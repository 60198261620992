import { type QueryHookOptions, type QueryResult, useQuery } from '@apollo/client'
import {
  type GetSearchResultsPageByUrlQuery,
  type SearchOrigin,
  type SearchResultsByUrlInput,
  GetPendingSearchInputDocument,
  GetSearchResultsPageByUrlDocument,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { getPaginationFromUrl } from '@/domain/srp/pagination/getPaginationFromUrl'
import { currentSrpUrl } from '@/lib/apollo/currentSrpUrl'
import { FEATURE_FLAG } from '@/lib/optimizely'

import { useSearchLoadingState } from './useSearchLoadingState'

export type SearchResultsPageByUrlInput = { searchResultsByUrlInput: SearchResultsByUrlInput }

type Result<TQuery extends GetSearchResultsPageByUrlQuery> = Omit<
  QueryResult<TQuery, SearchResultsPageByUrlInput>,
  'data'
> & {
  data?: TQuery['searchResultsPageByUrl']
  setLoadingStates: (params: { filters?: boolean; results?: boolean }) => void
  loadingResults: boolean
  loadingFilters: boolean
}

export function useGetSearchResultsData(
  options?: QueryHookOptions<GetSearchResultsPageByUrlQuery, SearchResultsPageByUrlInput>,
  origin?: SearchOrigin
): Result<GetSearchResultsPageByUrlQuery> {
  const { query } = useRouter()
  const srpUrl = getSrpComposedUrl(query)
  const srpUrlString = srpUrl.href
  const previousSrpUrl = currentSrpUrl()

  const { loadingResults, loadingFilters, setLoadingStates } = useSearchLoadingState()
  const { fetchPolicy, ...otherOptions } = options || {}

  /**
   * It should get the cache saved on the server-side
   * It should also respect the URL pagination to retrieve the correct cached value
   * */
  const {
    data: newData,
    previousData,
    loading: newDataLoading,
    called,
    error,
    client,
    ...rest
  } = useQuery<GetSearchResultsPageByUrlQuery, SearchResultsPageByUrlInput>(
    GetSearchResultsPageByUrlDocument,
    {
      context: {
        ...(options?.context || {}),
        experiments: [
          FEATURE_FLAG.PERFORMANCE_LISTING_COUNT,
          FEATURE_FLAG.INDEX_AUTOS_ATTRIBUTES,
          FEATURE_FLAG.SIBLING_CATEGORY_FACETS,
          FEATURE_FLAG.RELEVANCY_SORT_EXPERIMENT,
          FEATURE_FLAG.SEARCH_FOR_FREE_STUFF,
        ],
      },
      fetchPolicy: fetchPolicy || 'cache-only',
      variables: {
        searchResultsByUrlInput: {
          url: srpUrl.href,
          pagination: getPaginationFromUrl(srpUrl.href),
          origin,
        },
      },
      ...otherOptions,
    }
  )

  useEffect(() => {
    // TODO: there must be a better way to handle knowing when the new data is loaded
    if (!newDataLoading && !error && !!newData && srpUrlString !== previousSrpUrl) {
      client.writeQuery({
        query: GetPendingSearchInputDocument,
        data: {
          srp: { pendingSearchInput: null },
        },
      })

      // loading is done we need to save the url to state to use as next load fill
      currentSrpUrl(srpUrlString)
      setLoadingStates(false)
    }
  }, [newData, error, newDataLoading, srpUrlString, previousSrpUrl, setLoadingStates, client])

  /**
   * If the main/new data is empty, grab from preview data to prevent layout shift
   */
  const newOrPreviousData = newData || previousData

  return {
    setLoadingStates,
    loading: newDataLoading,
    loadingResults,
    loadingFilters,
    error,
    called,
    client,
    data: newOrPreviousData?.searchResultsPageByUrl || undefined,
    ...rest,
  }
}
